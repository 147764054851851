import imagesLoaded from 'imagesloaded';

function loadVideo(config) {
  const { el, src } = config;
  const newIframe = document.createElement('iframe');
  newIframe.src = `https://www.youtube.com/embed/${src}`;
  newIframe.setAttribute('frameborder', '0');
  newIframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
  newIframe.setAttribute('allowfullscreen', '1');
  el.appendChild(newIframe);
}

document.addEventListener("DOMContentLoaded", () => {
  let lazyElems = [].slice.call(document.querySelectorAll(".lazy"));

  if ("IntersectionObserver" in window) {
    window.lazyElemObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyElem = entry.target;
          lazyElem.src = lazyElem.dataset.src;
          const { bgsrc, srcset, video } = lazyElem.dataset;

          if (srcset) {
            lazyElem.srcset = srcset;
          }

          if (bgsrc) {
            lazyElem.style.backgroundImage = `url(${bgsrc})`;
          }

          if (video) {
            loadVideo({ el: lazyElem, src: video });
          }

          window.lazyElemObserver.unobserve(lazyElem);

          imagesLoaded(lazyElem, () => {
            lazyElem.classList.remove("lazy");
            // TODO: fix es-linter
            // eslint-disable-next-line no-restricted-syntax
            for (const key in lazyElem.dataset) {
              // eslint-disable-next-line no-prototype-builtins
              if (lazyElem.dataset.hasOwnProperty(key)) {
                lazyElem.removeAttribute(`data-${key.split(/(?=[A-Z])/).join("-").toLowerCase()}`);
              }
            }
          });

        }
      });
    });

    lazyElems.forEach((lazyElem) => {
      window.lazyElemObserver.observe(lazyElem);
    });
  } else {
    // fallback
    let active = false;
    const lazyLoad = () => {
      if (active === false) {
        active = true;

        setTimeout(() => {
          lazyElems.forEach((lazyElem) => {
            if ((lazyElem.getBoundingClientRect().top <= window.innerHeight && lazyElem.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyElem).display !== "none") {
              // eslint-disable-next-line no-param-reassign
              lazyElem.src = lazyElem.dataset.src;
              if (lazyElem.dataset.srcset) {
                // eslint-disable-next-line no-param-reassign
                lazyElem.srcset = lazyElem.dataset.srcset;
              }
              if (lazyElem.dataset.bgsrc) {
                // eslint-disable-next-line no-param-reassign
                lazyElem.style.backgroundImage = `url(${lazyElem.dataset.bgsrc})`;
              }
              if (lazyElem.dataset.video) {
                loadVideo(lazyElem, lazyElem.dataset.video);
              }


              imagesLoaded(lazyElem, () => {
                lazyElem.classList.remove("lazy");
                // eslint-disable-next-line no-restricted-syntax
                for (const key in lazyElem.dataset) {
                  // eslint-disable-next-line no-prototype-builtins
                  if (lazyElem.dataset.hasOwnProperty(key)) {
                    lazyElem.removeAttribute(`data-${key.split(/(?=[A-Z])/).join("-").toLowerCase()}`);
                  }
                }
              });


              lazyElems = lazyElems.filter((image) => {
                return image !== lazyElem;
              });

              if (lazyElems.length === 0) {
                document.removeEventListener("scroll", lazyLoad);
                window.removeEventListener("resize", lazyLoad);
                window.removeEventListener("orientationchange", lazyLoad);
              }
            }
          });

          active = false;
        }, 200);
      }
    };

    document.addEventListener("scroll", lazyLoad);
    window.addEventListener("resize", lazyLoad);
    window.addEventListener("orientationchange", lazyLoad);
  }
});
