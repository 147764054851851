import { CLASSES } from '../_consts';

const { isActive } = CLASSES;

export default function mobMenu() {
  const $subMenuButton = $('.js-submenu-button');
  const subMenu = '.js-submenu';
  const item = '.js-menu-item';

  $subMenuButton.on('click', e => {
    const $target = $(e.currentTarget);
    const internalSubmenu = $target.next(subMenu);
    const itemParentSiblings = $target.closest(item).siblings(item);
    const subMenuSiblings = itemParentSiblings.find($subMenuButton);

    subMenuSiblings.removeClass(isActive);
    subMenuSiblings.next(subMenu).hide()
    
    $target.toggleClass(isActive);
    internalSubmenu.toggle();
    

  })
}