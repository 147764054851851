import { createPopper } from '@popperjs/core';
import debounce from 'lodash.debounce';

export default function initTooltips() {

  window.createTooltips = () => {
    const tooltips = Array.prototype.slice.call(document.querySelectorAll('.js-tooltip'));
    const instances = [];

    if (!tooltips.length) return;

    tooltips.forEach(el => {
      const isMobile = window.matchMedia('(max-width: 599px)').matches;
      const content = el.querySelector('.js-tooltip-content');
      const initPlacement = (isMobile ? content.dataset.popperPlacementMob : false) || content.dataset.popperPlacement;
      const { strategy, timeout } = content.dataset;
      const offsetX = content.dataset.offsetX ? parseInt(content.dataset.offsetX, 10) : 0;
      const offsetY = content.dataset.offsetY ? parseInt(content.dataset.offsetY, 10) : 0;
      const flip = content.dataset.flip ? content.dataset.flip.split(", ") : ['left', 'top', 'bottom', 'right'];
      const popperInstance = createPopper(el, content, {
        placement: initPlacement || "bottom",
        strategy: strategy || 'fixed',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: flip,
            },
          }, {
            name: 'offset',
            options: {
              offset: ({ placement }) => {
                if (placement === 'bottom' && initPlacement !== 'bottom') {
                  return [0, 20];
                }
                if (placement === 'left' && initPlacement !== 'left') {
                  return [0, 20];
                }
                if (placement === 'right' && initPlacement !== 'right') {
                  return [0, 20];
                }
                if (placement === 'top' && initPlacement !== 'top') {
                  return [0, 20];
                }
                return [offsetX, offsetY];
              },
            },
          }, {
            name: 'arrow',
            options: {
              element: "[data-popper-arrow]"
            },
          }, {
            name: 'computeStyles',
            options: {
              adaptive: false,
              gpuAcceleration: false
            },
          },
          {
            name: 'eventListeners',
            options: {
              scroll: false,
              resize: false,
            },
          }
        ],
      });

      instances.push(popperInstance);
      const showEvents = ['mouseenter', 'focus'];
      const hideEvents = ['mouseleave', 'blur'];

      showEvents.forEach(event => {
        el.addEventListener(event, () => {
          content.setAttribute('data-show', '');

          if (timeout && isMobile) {
            setTimeout(() => {
              content.removeAttribute('data-show');
            }, parseInt(timeout, 10));
          }

        });
      });

      hideEvents.forEach(event => {
        el.addEventListener(event, () => {
          content.removeAttribute('data-show');
        });
      });

    });


    // Performance fix for popper instances
    const updateEvents = ['scroll', 'resize'];
    window.instances = instances;

    window.updateInstance = () => {
      instances.forEach((ins) => {
        ins.update();
      });
    };

    window.debouncedScroll = debounce(window.updateInstance, 300);
    updateEvents.forEach(event => {
      window.addEventListener(event, window.debouncedScroll);
    });
  };

  window.createTooltips();

  window.destroyTooltips = () => {
    const events = ['scroll', 'resize'];
    events.forEach(event => {
      window.removeEventListener(event, window.debouncedScroll);
    });
  }
}
