import './helpers/_detectTouch';
import './modules/_lazy';
import './polyfills/index';
import './libs/jquery.actual';
import svg4everybody from 'svg4everybody';
import burgerToggle from './modules/_burger';
import cart from './modules/_cart';
import initDropdowns from './modules/_dropdowns';
import Modal from './modules/_modals';
import scrollBar from './modules/_scrollBar';
import scrollToAnchor from './modules/_scrollTo';
import toTop from './modules/_scrollTop';
import textareaCounter from './modules/_textareaCounter';
import modalRelative from './modules/_modalRelative';
import toggler from './modules/_simple-toggle';
import initTooltips from './modules/_tooltip';
import menu from './modules/_menu';
import mobMenu from './modules/_mob-menu';
import initMask from './modules/_imask';
import contentWrapper from './modules/_content-toggle';
import sticky from './modules/_sticky';
import Validation from './modules/_validator';
// import demoSlider from './sliders/demoSlider/demoSlider';

document.addEventListener('DOMContentLoaded', () => {
  // svg4everybody({
  //   polyfill: true,
  // });
  svg4everybody();
  initTooltips();
  menu();
  mobMenu();
  sticky();
  contentWrapper();
  initMask();
  scrollBar();
  toTop();
  initDropdowns();
  burgerToggle();
  scrollToAnchor();
  textareaCounter();
  cart();
  modalRelative();
  toggler();
  // demoSlider();

  window.validation = new Validation();
  window.validation.init();

  window.modal = new Modal();
  window.modal.init();


  function updateDealersMoreInfo () {
    [...document.querySelectorAll('.dealer-nav')].forEach((el) => {
      $(el).addClass('is-opened');
      const actualHeight = $(el).actual('outerHeight');
      $(el).removeClass('is-opened');

      if (actualHeight <= 28) { // 28 is temp height in css
        $(el).find('.js-dealer-nav-toggler').hide();
      } else {
        $(el).find('.js-dealer-nav-toggler').show();
      }
    })
  }
  updateDealersMoreInfo();

  window.addEventListener('resize', updateDealersMoreInfo);
});
