import { CLASSES, DOM } from '../_consts';

export default function modalRelative() {

  const $links = $('.js-modal-relative-link');
  const $modals = $('.js-modal-relative');

  $links.on('click', (e) => {
    const $link = $(e.currentTarget);
    let $modal;

    if ($link[0].hasAttribute('data-modal-target')) {
      const target = $link.attr('data-modal-target');
      $modal = $(`[data-modal="${target}"]`);
    } else {
      $modal = $link.find('.js-modal-relative');
    }

    $modals.not($modal).fadeOut(100);

    if ($modal.is(':visible')) {
      $modal.fadeOut(100);
    } else {
      $modal.fadeIn(100);
    }

    DOM.$body.addClass(CLASSES.isZoomDisabled);
  });

  DOM.$body.on('click', (e) => {
    if (! $(e.target).closest('.js-modal-relative-link').length ) {
      $modals.fadeOut(100);
      DOM.$body.removeClass(CLASSES.isZoomDisabled);
    }
  });
}
