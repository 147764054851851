import validator from 'validator';

export default class Validation {
  constructor(successCallback) {
    this.successCall = successCallback;
    $(document).arrive('.js-form-validate', () => {
      this.init();
      setTimeout(() => {
        window.instances.forEach((ins) => {
          ins.update();
        });
      }, 200)
    });
  }

  init() {
    this.forms = Array.prototype.slice.call(document.querySelectorAll('.js-form-validate'));
    this.constraints = {
      email: {
        presence: true,
        email: true
      },
      username: {
        presence: true
      }
    };
    if (!this.forms.length) return;
    this.forms.forEach((form) => {
      form.addEventListener('submit', this.handleFormSubmit.bind(this));
    })
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const validFields = [];
    const form = e.target;
    const fields = Array.prototype.slice.call(form.querySelectorAll('input.js-field-validate'));

    window.destroyTooltips();
    window.createTooltips();

    fields.forEach((field) => {
      // check only visible fields
      if ($(field).is(":hidden")) return;

      if (field.dataset.validationType === 'email') {
        let isValid = validator.isEmail(field.value, this.constraints);

        // set email always valid if registration checkbox unchecked (cause you don't need email for nonregistration purchases)
        const $regCheckbox = $(field).closest('form').find('.js-register-checkbox');
        if ($regCheckbox.length) {
          const isRegChecked = $regCheckbox.find('input').is(":checked");
          if (!isRegChecked) {
            isValid = true;
          }
        }

        validFields.push(isValid);

        if (!isValid) {
          Validation.errorInit(field);
        } else {
          Validation.successInit(field);
        }
      }

      if (field.dataset.validationType === 'empty') {
        const isEmpty = validator.isEmpty(field.value, this.constraints);
        validFields.push(!isEmpty);

        if (isEmpty) {
          Validation.errorInit(field);
        } else {
          Validation.successInit(field);
        }
      }

      if (field.dataset.validationType === 'phone') {
        const isPhoneFilled = validator.isLength(field.value, { min: 19 });
        validFields.push(isPhoneFilled);


        if (!isPhoneFilled) {
          Validation.errorInit(field);
        } else {
          Validation.successInit(field);
        }
      }

      if ($(form).find('.captchaValidatorForm').length) {
        if ($(form).hasClass('is-cpt-valid')) {
          validFields.push(true);
        } else {
          validFields.push(false);
        }
      }

      if (field.dataset.validationType === 'payment-code') {
        const isPaymentCodeValid = validator.isLength(field.value, { min: 8, max: 8 });
        validFields.push(isPaymentCodeValid);


        if (!isPaymentCodeValid) {
          Validation.errorInit(field);
        } else {
          Validation.successInit(field);
        }
      }
    });

    const allFieldsValid = validFields.every(v => v === true);

    if (allFieldsValid) {
      // run logic on all fields validated (check what logic by attributes, perhaps)
      Validation.onSuccess(e);
      if (this.successCall) this.successCall(e);
    } else {
      Validation.onError(e);
    }
  }

  static errorInit(field) {
    const $modal = $(field).parents('.js-modal');
    window.instances.forEach((ins) => {
      ins.update();
    });

    const $wrapper = $(field).parents('.js-field-wrapper');
    const tooltip = $wrapper.find('.js-tooltip-content');
    if ($modal.length && !$wrapper.hasClass('is-error')) {
      $modal.addClass('is-in-transition');
    }

    $wrapper.addClass('is-error');
    tooltip[0].setAttribute('data-show', '');

    setTimeout(() => {
      $modal.removeClass('is-in-transition');
    }, 400);
  }

  static successInit(field) {
    const $wrapper = $(field).parents('.js-field-wrapper');
    const tooltip = $wrapper.find('.js-tooltip-content');

    $wrapper.removeClass('is-error');
    tooltip[0].removeAttribute('data-show', '');
  }

  static onError(e) {
    const form = e.target;
    form.classList.remove('is-validated');
    form.classList.add('has-errors');
  }

  static onSuccess(e) {
    const form = e.target;
    const { formName, modalTarget } = form.dataset;
    form.classList.add('is-validated');
    form.classList.remove('has-errors');

    if ($(form).hasClass('js-validate-callback')) return;

    $(form).parents('.js-presuccess').hide();
    $(`[data-form-success=${formName}]`).show();
    window.modal.deactivateModal(e);

    if (modalTarget && modalTarget.length) {
      window.modal.openTrigger(e);
    }
  }
}
