import { CLASSES, DOM } from '../_consts';

export default function scrollToAnchor() {
  const links = Array.prototype.slice.call(document.querySelectorAll('.js-scrollto-anchor'));
  const header = document.querySelector('.js-header');
  const headerMob = document.querySelector('.js-mob-header');
  const headerHeight = $(header).outerHeight() || $(headerMob).outerHeight() || 0;

  links.forEach((link) => {
    link.addEventListener('click', (e) => {
      const el = e.currentTarget;
      const targetAttr = el.dataset.scrollTargetLink;
      const $target = $(`[data-scroll-target=${targetAttr}]`);
      const duration = el.dataset.duration ? el.dataset.duration : 1000;

      const $mobMenu = $('.js-mob-menu')
      if ($mobMenu) {
        $mobMenu.removeClass(CLASSES.isOpen)
      }
      DOM.$body.removeClass(CLASSES.modalOpened, CLASSES.isHeaderOpen)

      if (!$target.length) return;

      e.preventDefault();

      $('html, body').animate(
        {
          scrollTop: $target.offset().top - headerHeight
        }, {
          duration,
          step(now, fx) {
            const newOffset = $target.offset().top - headerHeight;
            if (fx.end !== newOffset)
              // eslint-disable-next-line no-param-reassign
              fx.end = newOffset;
          }
        }
      );
    })
  });
}
