import { CLASSES } from '../_consts';

const { isActive } = CLASSES;

export default function menu() {
  const $menuItem = $('.js-menu-item');
  const subMenu = '.js-menu-submenu';
  const subMenuItem = '.js-submenu-item';

  $menuItem.on('mouseover', e => {
    const $target = $(e.currentTarget);
    const subMenuEl = $target.find(subMenu);

    $target.addClass(isActive).siblings().removeClass(isActive);
    subMenuEl.show();
  });

  $menuItem.on('mouseout', e => {
    const $target = $(e.currentTarget);
    const subMenuEl = $target.find(subMenu);

    $target.removeClass(isActive);
    subMenuEl.hide();
  });

  $menuItem.on('mouseover', subMenuItem, e => {
    const $target = $(e.currentTarget);
    $target.addClass(isActive).siblings().removeClass(isActive);

    if ($target.hasClass('is-simple')) {
      $target.closest('.js-submenu-wrapper').addClass('is-simple');
    } else {
      $target.closest('.js-submenu-wrapper').removeClass('is-simple');
    }
  })
}
