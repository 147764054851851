import { CLASSES } from '../_consts';

const { isSticky, isOut } = CLASSES;

const sticky = () => {
  const $menu = $('.js-menu-wrapper');
  const $header = $('.js-header');
  const $out = $('.js-out');

  $(window).on('scroll', e => {
    const scroll = $(e.currentTarget).scrollTop();

    if (scroll > 200 && window.matchMedia('(min-width: 1024px)').matches) {
      $menu.addClass(isSticky);
      $header.addClass(isSticky);
      $out.addClass(isSticky);
    } else if (!$('body').hasClass('is-modal-opened')) {
      $menu.removeClass(isSticky);
      $header.removeClass(isSticky);
      $out.removeClass(isSticky);
    }

    if (scroll > 220 && window.matchMedia('(min-width: 1024px)').matches) {
      $header.addClass(isOut);
    } else if (!$('body').hasClass('is-modal-opened')) {
      $header.removeClass(isOut);
    }
  })

};

export default sticky;
