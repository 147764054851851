import { CLASSES } from '../_consts';

const { isExpanded } = CLASSES;

const contentSwapper = () => {
  const wrapper = '.js-content-swap';
  const more = '.js-content-more';
  const $button = $('.js-toggle-content');
  const toggleHeight = ($(more).attr('data-toggle-height') === 'true');


  $button.on('click', e => {
    const $target = $(e.currentTarget);
    const wrapperParent = $target.closest(wrapper);
    const moreContent = wrapperParent.find(more);

    wrapperParent.toggleClass(isExpanded);
    $target.toggleClass(isExpanded);
    if (!toggleHeight) moreContent.toggle();
  })
};

export default contentSwapper;
