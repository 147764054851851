import PerfectScrollbar from 'perfect-scrollbar';
import 'arrive';

// TODO: refactor after back implementation
export default function scrollBar() {
  const isMobile = window.matchMedia('(max-width: 599px)').matches;
  let ps;
  window.psInstances = [];

  $(document).arrive('.js-scrollbar', () => {
    window.destroyCustomScroll();
    window.initCustomScroll();
  });


  window.initCustomScroll = () => {
    const scrollContainers = Array.prototype.slice.call(document.querySelectorAll('.js-scrollbar'));
    if (!scrollContainers.length) return;

    scrollContainers.forEach((el) => {
      if (isMobile && $(el).hasClass('js-scrollbar-desktop')) return;

      const suppress = el.dataset.suppressScroll;
      const scrollYMarginOffset = el.dataset.scrollYMarginOffset ? el.dataset.scrollYMarginOffset : false;

      ps = new PerfectScrollbar(el, {
        wheelSpeed: 2,
        wheelPropagation: true,
        scrollYMarginOffset,
        suppressScrollY: suppress === 'y',
        suppressScrollX: suppress === 'x',
      });
      window.psInstances.push(ps)
    });
  };

  window.initCustomScroll();
  window.destroyCustomScroll = () => {
    window.psInstances.forEach((ins) => {
      ins.destroy();
    });
    window.psInstances = [];
  }

}
