export default function textareaCounter() {
  const $textarea = $('.js-textarea-counter');
  if ($textarea.length) {
    $textarea.on('input', (e) => {
      const { length } = e.currentTarget.value;
      const $counter = $(e.currentTarget).siblings('.js-textarea-accelerator');
      $counter.find('span').text(length);
    });
  }
}
