import { CLASSES, DOM } from '../_consts';

export default function cart() {

  const { isActive } = CLASSES;

  // Delete cart item popup
  DOM.$body.on('click', '.js-delete-cart-item', (e) => {
    const $btn = $(e.currentTarget);
    const $item = $btn.closest('.js-cart-item');
    const $popup = $item.find('.js-item-actions');
    $popup.addClass(isActive);
  });

  DOM.$body.on('click', (e) => {
    if (!$(e.target).closest('.js-delete-cart-item').length) {
      $('.js-item-actions').removeClass(isActive);
    }
  });


  // Promo
  const $promoLink = $('.js-cart-promo-link');

  $promoLink.on('click', (e) => {
    const $link = $(e.currentTarget);
    const $promoBody = $link.siblings('.js-cart-promo-body');
    $promoBody.toggleClass(isActive);
    $link.toggleClass(isActive);
  });

}
