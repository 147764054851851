import { CLASSES } from '../_consts';

export default function toggler() {
  const $trigger = $('.js-toggler');
  const target = '.js-toggler-target';

  $trigger.on('click', (e) => {
    e.preventDefault();
    const $current = $(e.currentTarget);
    const toggleEl = $current.data('target');
    const $content = $(`${target}[data-toggle="${toggleEl}"]`);

    if ($current.hasClass('js-toggle-slide')) {
      if ($content.hasClass(CLASSES.isActive)) {
        $content.slideUp()
      } else {
        $content.slideDown();
      }
    }

    $current.toggleClass(CLASSES.isActive);
    $content.toggleClass(CLASSES.isActive);

    if (!$current.hasClass('js-toggle-more')) {
      $content.toggle();
    }
  })
}
