import debounce from 'lodash.debounce';
import { CLASSES } from '../_consts';
import isElementInViewport from '../helpers/_inView';

export default function toTop() {

  const backToTopElement = document.querySelector('.js-to-top');
  const footer = document.querySelector('.js-footer');

  if (!backToTopElement) return;

  const { isVisible, isFooter } = CLASSES;

  function toTopVisibility() {
    const scrolledToShow = window.pageYOffset > 50;

    const isDarkBtn = footer ? isElementInViewport(footer) : false;

    if (scrolledToShow) {
      backToTopElement.classList.add(isVisible);
    } else {
      backToTopElement.classList.remove(isVisible);
    }

    if (isDarkBtn) {
      backToTopElement.classList.add(isFooter);
    } else {
      backToTopElement.classList.remove(isFooter);
    }
  }

  toTopVisibility();
  backToTopElement.addEventListener('click', () => {
    $('html, body').animate({
      scrollTop: 0
    }, 400);
  });

  const debouncedScroll = debounce(toTopVisibility, 200);
  document.addEventListener('scroll', () => {
    debouncedScroll();
  });
}
