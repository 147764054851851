import 'jquery-clickout';
import { CLASSES, DOM } from '../_consts';
import Utility from '../helpers/Utility';
import 'arrive';


export default function initDropdowns() {
  const { isOpen, isHeaderOpen, isDropdownOpened } = CLASSES;
  const { $body, $burger, $menu, $header } = DOM;

  $(document).arrive(".js-dropdown-link", () => {
    window.dropdownsDestroy();
    window.dropdowns();
  });

  function documentRemoveDropdowns(e) {
    const $targetLink = $('.js-dropdown-link');
    const dropClass = '.js-dropdown';
    if (!$targetLink.is(e.target) && $targetLink.has(e.target).length === 0) {
      $(dropClass).removeClass(isOpen);
      $body.removeClass(isDropdownOpened);
    }
  }

  window.dropdowns = () => {
    const $targetLink = $('.js-dropdown-link');
    const dropClass = '.js-dropdown';
    const $dropdowns = $(dropClass);
    const $close = $('.js-dropdown-close');

    if ($targetLink.length) {
      $targetLink.each((index, el) => {
        $(el).on('click', (e) => {
          const target = e.currentTarget;

          if ($body.hasClass(isHeaderOpen)) {
            $burger.removeClass(isOpen);
            $menu.removeClass(isOpen);
            $header.removeClass(isOpen);
            Utility.removeBodyHidden();
            $body.removeClass(isHeaderOpen);
          }

          const targetAttr = $(target).attr('data-dropdown-target');
          const $dropdown = $(`.js-dropdown[data-dropdown="${targetAttr}"]`);
          const overlayStatus = !$dropdown.attr('data-overlay');


          const isLinkClicked = !$dropdown.has(e.target).length && $dropdown.hasClass(isOpen);

          if (isLinkClicked) {
            $dropdowns.removeClass(isOpen);
            $body.removeClass(isDropdownOpened);
          } else {
            $dropdowns.removeClass(isOpen);
            $dropdown.addClass(isOpen);
            if (overlayStatus) $body.addClass(isDropdownOpened);

            // fix dropdown custom scrollbar inside fixed elements
            const ps = $(el).find('.js-scrollbar');

            if (ps.length) {
              setTimeout(() => {
                ps[0].dispatchEvent(new CustomEvent('scroll'));
              }, 200)
            }

            setTimeout(() => {
              window.instances.forEach((ins) => {
                ins.update();
              });
            }, 200)
          }
        });
      });

      $(document).on('click', documentRemoveDropdowns);

      $close.click((e) => {
        const $target = $(e.currentTarget).closest(dropClass);
        if ($target.hasClass(isOpen)) {
          setTimeout(() => {
            $target.removeClass(isOpen);
            $body.removeClass(isDropdownOpened);
          }, 150)
        }
      });
    }
  };

  window.dropdownsDestroy = () => {
    const $targetLink = $('.js-dropdown-link');
    $targetLink.off('click');
    $(document).off('click', documentRemoveDropdowns);
  };

  window.dropdowns();
}
