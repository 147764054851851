import debounce from 'lodash.debounce';
import { DOM, CLASSES } from '../_consts';
import Utility from '../helpers/Utility';


export default class Modal {
  constructor() {
    this.modalSelector = '.js-modal';
    this.bodyHiddenStatus = 0;
    this.FADE_TIME = 120;
    this.classes = {
      isFaded: 'is-faded'
    }
  }

  init() {
    this.initDOM();
    this.modalElLength = this.modalEl.length;
    if (this.modalElLength) {
      this.initHandlers();
      this.active = true;
    } else {
      this.active = false;
    }
  }

  initDOM() {
    this.modalLink = Array.prototype.slice.call(document.querySelectorAll('.js-modal-link'));
    this.modalEl = Array.prototype.slice.call(document.querySelectorAll('.js-modal'));
    this.modalCloseBtn = Array.prototype.slice.call(document.querySelectorAll('.js-close-modal'));
    this.modalScroll = Array.prototype.slice.call(document.querySelectorAll('.js-modal-update-scroll'));
  }

  initHandlers() {
    $(this.modalEl).on('click', '.js-close-modal', this.deactivateModal.bind(this));
    $(document).on('click', '.js-modal-link', this.openTrigger.bind(this));
    $(this.modalCloseBtn).on('click', this.deactivateAllModals.bind(this));
    $(this.modalScroll).on('scroll', debounce(Modal.scrollContentHandle, 200));
  }

  destroy() {
    $(this.modalEl).off('click');
    $(this.modalLink).off('click');
    $(this.modalCloseBtn).off('click');
  }

  openTrigger(e) {
    e.preventDefault();
    const current = e.currentTarget;
    const targetAttr = $(current).attr('data-modal-target');
    const $target = $(`.js-modal[data-modal="${targetAttr}"]`);
    this.open($target);
  }

  deactivateModal(e) {
    e.preventDefault();
    const $modal = $(e.target.closest(this.modalSelector));
    if (!$modal.length) return;
    $modal.removeClass(CLASSES.isOpen);

    const fadeTime = parseInt($modal.attr('data-modal-time'), 10) || this.FADE_TIME;
    $modal.fadeOut(fadeTime);
    $modal.removeClass(fadeTime);
    this.updateStatus();
  }

  deactivateAllModals() {
    this.modalEl.forEach(element => {
      const $el = $(element);
      const fadeTime = parseInt($el.attr('data-modal-time'), 10) || this.FADE_TIME;
      $el.fadeOut(fadeTime);
      $el.removeClass(this.classes.isFaded);
      $el.removeClass(CLASSES.isOpen);
    });
  }

  open(el) {
    if (!$(el).hasClass(CLASSES.isOpen)) this.deactivateAllModals();

    const fadeTime = parseInt($(el).attr('data-modal-time'), 10) || this.FADE_TIME;

    $(el).fadeIn(fadeTime, () => {
      setTimeout(() => {
        $(el).addClass(this.classes.isFaded);
      }, fadeTime * 2)
    });

    $(el).addClass(CLASSES.isOpen);
    Modal.updateTooltips(el);
    Modal.updateScrollbar(el);

    if (!DOM.$body.hasClass(CLASSES.modalOpened)) {
      Utility.addBodyHidden();
    }

    this.timeOutClose(el);
  }

  static updateTooltips(el) {
    $(el).addClass('is-in-transition');
    setTimeout(() => {
      window.instances.forEach((ins) => {
        ins.update();
      });
    }, 200);
    setTimeout(() => {
      $(el).removeClass('is-in-transition');
    }, 600);
  }

  static updateScrollbar(el) {
    const ps = $(el).find('.js-scrollbar');
    if (ps.length) {
      setTimeout(() => {
        ps[0].dispatchEvent(new CustomEvent('scroll'));
      }, 1000)
    }
  }

  timeOutClose(el) {
    const timeoutClose = $(el).attr('data-timeout-close');
    if (timeoutClose) {
      setTimeout(() => {
        this.close(el)
      }, parseInt(timeoutClose, 10))
    }
  }

  updateStatus() {
    if (this.bodyHiddenStatus === 1) {
      this.bodyHiddenStatus = 0;
    } else {
      DOM.$body.removeClass(CLASSES.modalOpened);
      Utility.removeBodyHidden();
    }
  }

  static scrollContentHandle() {
    window.instances.forEach((ins) => {
      ins.update();
    });
  }

  close(el) {
    $(el).fadeOut(this.FADE_TIME);
    $(el).removeClass(this.classes.isFaded);
    $(el).removeClass(CLASSES.isOpen);
    this.updateStatus();
  }
}
