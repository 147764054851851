import IMask from 'imask';

export default function initMask() {

  window.initMask = () => {
    const masks = Array.prototype.slice.call(document.querySelectorAll('.js-field-mask'));

    if (!masks.length) return;

    masks.forEach((el) => {
      const pattern = el.dataset.mask;
      IMask(el, {
        mask: pattern
      });
    });
  };

  window.initMask();

  $(document).arrive('.js-field-mask', () => {
    window.initMask();
  });
}
